@keyframes loader {
	0% { width: 0; }
	20% { width: 10%; }
	25% { width: 24%; }
	43% { width: 41%; }
	56% { width: 50%; }
	66% { width: 52%; }
	71% { width: 60%; }
	75% { width: 76%; }
	94% { width: 86%; }
	100% { width: 100%; }
}

.ProgressBar {
	border-radius: 60px;
	overflow: hidden;
  width: 100%;
  height: 5px;
  margin-top: 4px;
}

.ProgressBar :global span {
  display: block;
}

.ProgressBar :global .Bar {
  background: rgba(0,0,0,0.075);
}

.ProgressBar :global .Progress :local {
  animation: loader 8s ease forwards;
  /* Change the animation fill mode 'infinite' to 'forwards' to stop the animation from repeating. */
  background: #75b800;
  color: #fff;
  padding: 5px;
  width: 0;
}