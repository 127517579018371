@import '../../vars.scss';

.StepControl {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ccc;
  cursor: pointer;

  &:hover {
    background-color: tint($brandBlue, 80);
  }

  &:active {
    color: white;
    background-color: $brandBlue;
  }
}