@import '../../vars.scss';

.SliderControl {
  width: 100%;

  .Control {
    -webkit-appearance: none;
    width: 100%;
    height: 24px;
    background: #f0f0f0;
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
  }
  
  .Control:hover {
    opacity: 1;
  }

  .Control::-webkit-slider-thumb {
    appearance: none;
    width: 24px;
    height: 24px;
    background: #ccc;
    border-radius: 2px;
    border: 2px solid #f0f0f0;
    cursor: pointer;
  }
}