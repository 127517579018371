.Admin {
  display: flex;
  flex-direction: column;

  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}