.MapView {
  .GrabberContainer {
    position: absolute;
    z-index: 1;
    transition: top 200ms ease-in-out, left 200ms ease-in-out;
  }

  .GrabberName {
    text-transform: uppercase;
    font-size: 0.5em;
    margin-left: -50%;
    font-weight: 700;
    background-color: rgba(255, 255, 255, 0.5);
    padding: 2px 0;
    text-align: center;
    border-radius: 2px;
  }

  .Grabber {
    width: 20px;
    height: 20px;
    background: red;
    border: 2px solid darkred;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border-radius: 6px;
  }
}