/// Slightly lighten a color
/// @access public
/// @param {Color} $color - color to tint
/// @param {Number} $percentage - percentage of `$color` in returned color
/// @return {Color}
@function tint($color, $percentage) {
  @return mix(white, $color, $percentage);
}

.ErrorBox {
  padding: 16px;
  background-color: tint(#FC3868, 80%);
  border-radius: 8px;
  margin-top: 8px;
}