@import './vars.scss';

.App {
  display: flex;
  align-items: center;
  flex-direction: row;
  max-height: 1024px;
  overflow: hidden;

  select {
    padding: 1em;
  }

  @media print { 
    .button,
    .DropDown {
      display: none;
    }
  }
 
  * {
    box-sizing: border-box;
  }

  .SideMenu {
    display: flex;
    flex-direction: column;
    width: 200px;
    box-shadow: $bigShadow;
    height: 100vh;
    padding: 20px;

    img {
      margin-bottom: 16px;
    }
    
    & > * {
      margin-top: 16px;
    }

    .button {
      margin-left: unset;
      text-decoration: unset;
    }

    .button.active {
      background: $brandDarkBlue;
      border: 4px solid rgb(86, 145, 196);
    }

    .replay {
      margin-bottom: auto;
      // margin-top: auto;
    }
  }

  .Content {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: auto;
  }
}

.MapView {
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: 20px 20px;
  background-image:
    linear-gradient(to right, #eee 1px, transparent 1px),
    linear-gradient(to bottom, #eee 1px, transparent 1px);
}

.MapView * {
  box-sizing: border-box;
}

.MapView .d3-component {
  /* display: flex; */
  position: relative;
}

.MapView .d3-component .bay {
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
}

.bay > .Text {
  font-size: .75em;
}

.bay.Empty { background: rgb(168,184,193); }
.bay.Full { background: rgb(109,197,99); }
.bay.Loading { background: rgb(248,162,77); }
.bay.Unloading { background: rgb(234,104,110); }
.bay.Split::after { }

.Auth {
  display: flex;
  flex-direction: column;
  width: 300px;
  padding: 24px;
}

input:disabled {
  opacity: 0.5;
}

.mover {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 0.5em;
  width: 34px;
  height: 34px;
  border-radius: 17px;
  border: 2px solid red;
  background: red;
}

.moversRoot,
.mapRoot {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-transform: uppercase;
}

.MapBackground {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

a.button,
span.button,
button.button {
  height: 44px;

  & + .button {
    margin-left: $padding;
  }

  &.round {
    width: 46px;
    padding: 0;
  }

  padding: 0 $paddingLarge;
  box-sizing: border-box;
  border: none;
  border-radius: 100px;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &.small {
    padding: 0 $paddingMedium;
    height: 36px;
  }
  
  background: $brandBlue;
  color: $brandWhite;

  &.danger {
    background: $brandRed;
  }

  &.secondary {
    background: $brandWhite;
    color: $brandBlue;

    &:hover {
      opacity: 1;
      background: lighten($brandBlue, 40%);
    }
    &:active {
      opacity: 1;
      background: lighten($brandBlue, 30%);
    }
  }

  font-family: $font1;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.05em;
  text-transform: uppercase;

  user-select: none;


  transition: box-shadow 200ms, opacity 200ms, background-color 200ms;
  border: 2px solid transparent;

  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 1;
  }

  &:focus {
    border-color: #00000033;
    outline: none;
  }

  &.wide {
    width: 100%;
  }

  &.disabled, &:disabled {
    &, &:hover, &:active {
      cursor: default;
      box-shadow: none;
      opacity: 1;
      color: $borderGrey;
      background: #E6E6EC;//$brandLightGrey;
    }
  }
}

.Toastify__toast-body {
  white-space: pre-line;
}