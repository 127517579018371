@import '../../vars.scss';

.ReplayMap {
  display: flex;
  flex-direction: column;

  .ActionRow {
    display: flex;
    flex-direction: row;

    button {
      margin-left: auto;
    }

    .Confirmation {
      height: 185px;

      .ModalContent {
        display: flex;
        flex-direction: column;
  
        span {
          margin-bottom: 1em;
        }
  
        .button {
          margin-right: 1em;
        }
      }
    }
  }

  .PlaceholderMap {
    background-color: rgb(243, 243, 243);
    width: 900px;
    height: 700px;
  }

  .DropDown {
    @include Label;

    & > span:nth-child(1) {
      margin-right: 1em;
      margin-bottom: 1em;
    }
  }
  
  .GeneralInfo {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 8px 0;
    
    @include Label;

    // .SelectedUpdate {}
    // .MapName {}
    // .SelectedTime {}
  }

  .SliderContainer {
    display: flex;
    flex-direction: row;
    user-select: none;

    .Slider {
      flex: 1;
      position: relative;

      .HorizontalTimeline {
        position: absolute;
        top: 10px;
        left: 12px;
        right: 12px;
        pointer-events: none;
      }
    }

    .StepControl {
      width: 30px;
    }
  }
}