@import '../../vars.scss';

.HorizontalTimeline {
  position: relative;

  .Snapshot {
    display: block;
    position: absolute;
    top: 0;
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background-color: $brandGreen;
    opacity: 0.5;

    &.Selected {
      width: 16px;
      height: 16px;
      border-radius: 8px;
      transform: translateX(-4px) translateY(-4px);

      background-color: $brandOrange;
      box-shadow: 0 0 6px 0 $brandOrange;
      z-index: 1;
    }
  }
}