@import '../../vars.scss';

.ModalBackground {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0,0,0,0.5);
  z-index: 13;

  .Modal {
    position: relative;
    background: white;
    border-radius: 20px;
    border: 1px solid #ccc;
    padding: 30px 40px;
    min-width: 300px;
    max-width: 90vw;
    height: 80vh;

    .ModalContent {
      height: 100%;
      padding-top: 30px;
      overflow-y: scroll;
    }

    .Close {
      position: absolute;
      top: 10px;
      right: 10px;
      border: 1px solid #ccc;
      border-radius: 10px;
      padding: 1ch 2ch;
      cursor: pointer;
    }
    
    .Close:active {
      background-color: $brandBlue;
      opacity: 0.5;
    }
    .Close:hover {
      background-color: $brandBlue;
      color: white;
    }
  }
}

.QuickReconcileModal {
  width: 80vw;
}